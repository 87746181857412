import React, { useEffect, useState } from "react";
import ComponentSearch from "./small_components/component_search";
import "leaflet/dist/leaflet.css";
import ScrollableContainer from "./small_components/checkbox_endpoint";
import Invisible_Nonclickable from "./small_components/invisible_nonclickable";
import Activity_endpoint from "./small_components/checkbox_activities";
import { useParams } from "react-router";
import { Modal_view } from "./modal_voir";

export default function Search_main({
  setSelectedEnterprise,
  onOpenSearchModal,
  checkboxState,
  onCheckboxChange,
  id,
  name,
  toggleCheckboxState,
}) {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleDivClick = (index, location) => {
    setSelectedEnterprise(location);
    setActiveIndex(index);
  };

  const handleButtonClick = () => {
    console.log("Button clicked!");
  };

  const [locations, setLocations] = useState([]);
  const [useStaticImage, setUseStaticImage] = useState(false);
  const [categorie, setCategorie] = useState([]);
  const [activity, setActivity] = useState([]);

  const fallbackLocations = [
    {
      title: "RMS Static",
      time: "07:00 - 21:00",
      pNumber: "33 867 00 01",
      location: `15°43'23.2"N 18°28'35., Rue 1, Dakar`,
      quartier: `Ouakam Static`,
    },
    {
      title: "Life Static",
      time: "08:00 - 22:00",
      pNumber: "33 867 00 02",
      location: `16°43'43.2"N 19°29'45., Rue 2, Dakar`,
    },
    {
      title: "Fallback Sport 3",
      time: "06:00 - 19:00",
      pNumber: "33 867 00 03",
      location: `17°43'53.2"N 20°30'55., Rue 3, Dakar`,
    },
  ];

  const fallbackImages = [
    "/img/gym1.webp",
    "/img/lifefitness.jpg",
    "/img/running-icon.png",
    "/img/equality.png",
  ];

  useEffect(() => {
    const selectedCategories = Object.keys(checkboxState).filter(
      (key) => checkboxState[key]
    );
    setCategorie(selectedCategories);
  }, [checkboxState]);

  useEffect(() => {
    const selectedActivities = Object.keys(checkboxState).filter(
      (key) => checkboxState[key]
    );

    setActivity(selectedActivities);
  }, [checkboxState]);

  useParams(() => {});

  // Fetch data when categorie or activity changes
  useEffect(() => {
    if (categorie.length === 0 && activity.length === 0) return;

    const baseUrl = "https://www.api.ketket.fewnu.app/api/filtres?";
    const bothUrl = `${baseUrl}&categorie=${categorie.join(",")}`;
    const categorieUrl = `${bothUrl}`;
    const activityUrl = `&activites=${activity.join(",")}`;
    const categoriesParam = categorie.length
      ? `categorie=${categorie.join(",")}`
      : "";
    const activityParam = activity.length
      ? `&activites=${activity.join(",")}`
      : "";

    const url = `${baseUrl}?${categoriesParam}${
      activityParam ? "&" + activityParam : ""
    }`;

    fetch(bothUrl, {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data && Array.isArray(data.data)) {
          setLocations(data.data);
          console.log(bothUrl, "test");

          console.log(categoriesParam);
          console.log(activityParam);
        } else {
          console.warn("No valid data found in response, using fallback data.");
          alert(categorieUrl);
          setLocations(fallbackLocations);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error, "Using fallback data.");
        setLocations(fallbackLocations);
      });
  }, [categorie, activity]);

  const combinedButtonClickHandler = () => {
    handleButtonClick();
    toggleCheckboxState();
  };

  return (
    <>
      <div className="h- w-[100%] flex justify-center gap-[10px] overflow-y-hidden">
        <div className=" w-full grid grid-cols-2  items-center gap-2 overflow-hidden">
          <div className="bg-[#dde3ed] flex items-center rounded-md px-2 p-1 font-quicksand">
            <img src="../search.png" className="w-4 mr-2" />
            <input
              className="text-[12px] w-full bg-transparent py-1 font-bold placeholder:text-[#828282] placeholder:-mb-[10px] outline-0  "
              placeholder="Salle de sport, ou activité"
              type="text"
            />
          </div>
          <div className="bg-[#dde3ed] flex items-center justify-center rounded-md px-2 p-1 font-quicksand">
            <img src="../location.png" className="w-6 " />
            <input
              className="text-[12px] w-full bg-transparent py-1 text-sm font-bold placeholder:text-[#828282] placeholder:-mb-[10px] outline-0  "
              placeholder="Quartiers, Villes"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className=" w-[100%]  flex justify-center overflow-hidden">
        <button
          onClick={onOpenSearchModal}
          className="p-2  bg-white border-[#1D428A] border-2 rounded-[15px] mx-auto mt-[25px] flex items-center justify-center gap-[10px]"
        >
          <h4 className="text-sm text-main-blue font-bold font-quicksand">
            Filtrer les résultats
          </h4>
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 2.00001H9.17C9.3766 1.41448 9.75974 0.907443 10.2666 0.548799C10.7735 0.190154 11.3791 -0.00244141 12 -0.00244141C12.6209 -0.00244141 13.2265 0.190154 13.7334 0.548799C14.2403 0.907443 14.6234 1.41448 14.83 2.00001H17C17.2652 2.00001 17.5196 2.10537 17.7071 2.2929C17.8946 2.48044 18 2.73479 18 3.00001C18 3.26523 17.8946 3.51958 17.7071 3.70712C17.5196 3.89465 17.2652 4.00001 17 4.00001H14.83C14.6234 4.58554 14.2403 5.09258 13.7334 5.45122C13.2265 5.80986 12.6209 6.00246 12 6.00246C11.3791 6.00246 10.7735 5.80986 10.2666 5.45122C9.75974 5.09258 9.3766 4.58554 9.17 4.00001H1C0.734784 4.00001 0.48043 3.89465 0.292893 3.70712C0.105357 3.51958 0 3.26523 0 3.00001C0 2.73479 0.105357 2.48044 0.292893 2.2929C0.48043 2.10537 0.734784 2.00001 1 2.00001ZM1 10H3.17C3.3766 9.41448 3.75974 8.90744 4.2666 8.5488C4.77346 8.19015 5.37909 7.99756 6 7.99756C6.62091 7.99756 7.22654 8.19015 7.7334 8.5488C8.24026 8.90744 8.6234 9.41448 8.83 10H17C17.2652 10 17.5196 10.1054 17.7071 10.2929C17.8946 10.4804 18 10.7348 18 11C18 11.2652 17.8946 11.5196 17.7071 11.7071C17.5196 11.8947 17.2652 12 17 12H8.83C8.6234 12.5855 8.24026 13.0926 7.7334 13.4512C7.22654 13.8099 6.62091 14.0025 6 14.0025C5.37909 14.0025 4.77346 13.8099 4.2666 13.4512C3.75974 13.0926 3.3766 12.5855 3.17 12H1C0.734784 12 0.48043 11.8947 0.292893 11.7071C0.105357 11.5196 0 11.2652 0 11C0 10.7348 0.105357 10.4804 0.292893 10.2929C0.48043 10.1054 0.734784 10 1 10Z"
              fill="#1D428A"
            />
          </svg>
        </button>
      </div>
      <div className="h-[60px]  ml-[2.25%] min-w-[700px] overflow-x-auto flex gap-[9px]">
        {checkboxState?.salle_de_sport && (
          <ScrollableContainer
            title="Salle de Sport"
            onButtonClick={combinedButtonClickHandler}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.club_de_sport && (
          <ScrollableContainer
            title="Club"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.cours && (
          <ScrollableContainer
            title="Cours"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.football && (
          <Activity_endpoint
            title="Football"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.natation && (
          <Activity_endpoint
            title="Natation"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.badminton && (
          <Activity_endpoint
            title="Badminton"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.yoga && (
          <Activity_endpoint
            title="Yoga"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.karate && (
          <Activity_endpoint
            title="Karaté"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.boxe && (
          <Activity_endpoint
            title="Boxe"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.mma && (
          <Activity_endpoint
            title="MMA"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.gym && (
          <Activity_endpoint
            title="Gym"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.thai && (
          <Activity_endpoint
            title="Muay Thai"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.musculation && (
          <Activity_endpoint
            title="Musculation"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.jjb && (
          <Activity_endpoint
            title="Jiujitsu 🇧🇷"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.fitness && (
          <Activity_endpoint
            title="Fitness"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        {checkboxState?.autre && (
          <Activity_endpoint
            title="Autre"
            onButtonClick={handleButtonClick}
            onCheckboxChange={onCheckboxChange}
          />
        )}

        <Invisible_Nonclickable
          title="Salle de Sport"
          onButtonClick={handleButtonClick}
        />

        <Invisible_Nonclickable
          title="Salle de Sport"
          onButtonClick={handleButtonClick}
        />

        <Invisible_Nonclickable
          title="Salle de Sport"
          onButtonClick={handleButtonClick}
        />
        {/*

<ScrollableContainer
                title="Salle de Sport"
                onButtonClick={handleButtonClick}
            /> */}
      </div>
      <div className="h-[805px] w-[100%]  overflow-y-scroll">
        <div className="wrapper h-auto w-[100%] flex flex-col gap-[18px]">
          {locations.map((location, index) => (
            <ComponentSearch
              key={index}
              backgroundImage={
                location.images
                  ? `https://www.api.ketket.fewnu.app/storage/${location.images[0]}`
                  : fallbackImages[index % fallbackImages.length]
              }
              Title={
                location.nom ||
                fallbackLocations[index % fallbackLocations.length].title
              }
              Time={
                location.horaire ||
                fallbackLocations[index % fallbackLocations.length].time
              }
              pNumber={
                location.numero ||
                fallbackLocations[index % fallbackLocations.length].pNumber
              }
              Location={
                location.address ||
                fallbackLocations[index % fallbackLocations.length].location
              }
              location={location}
              locations={locations}
              fallbackLocations={fallbackLocations}
              fallbackImages={fallbackImages}
              isClicked={activeIndex === index}
              onDivClick={() => handleDivClick(index, location)}
            />
          ))}

          {/* <ComponentSearch backgroundImage={gym1}
                Title={"Life Fitness"}
                Time = {"10:00 - 22:00"}
                pNumber={"771542149"}
                Location={`40 Rue LIB 22, Dakar`}
                
                />
                <ComponentSearch backgroundImage={gym1}
                Title={"Dakar FitBox Club"}
                Time = {"11:00 - 22:00"}
                pNumber={"33 867 35 47"}
                Location={`14°43'33.2"N 17°27'35., Rue 6, Dakar`}
                
                />
                <ComponentSearch backgroundImage={gym1}
                Title={"Club Sport & Bien Être"}
                Time = {"06:30 - 22h00"}
                pNumber={"33 820 00 32"}
                Location={`Virage de Ngor, Lot numéro 18, Dakar 12000`}
                
                />             */}
          <div className="bg-white h-[228px] w-[662px] border-[#1D428A] border-2 rounded-[5px] shadow-shadow-search mt-[5px] ml-[36px]"></div>
          <div className="bg-white h-[228px] w-[662px] border-[#1D428A] border-2 rounded-[5px] shadow-shadow-search mt-[5px] ml-[36px]"></div>
        </div>
      </div>
    </>
  );
}
