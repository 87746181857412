import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "./navbar";
import Logos from "./partenaire_logos";
import Bouton from "./Bouton";
import { Footer } from "./Footer";
import { DevenirPartenaireComponent } from "./DevenirPartenaireComponent";
import { CardService } from "./CardService";
import { Apropos } from "./Apropos";
import { RechercheSection } from "./RechercheSection";
import { EtrePartenaire } from "./EtrePartenaire";

export default function Hero() {
  const navigate = useNavigate();

  const [articleWithId1, setArticleWithId1] = useState(null);
  const [awards, setAwards] = useState(null);

  // Fetch data when component mounts
  useEffect(() => {
    Promise.all([
      fetch("https://www.api.ketket.fewnu.app/api/articles", {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache", // Prevent caching
        },
      })
        .then((response) => response.json())
        .then((data) => data.data || []),

      fetch("https://www.api.ketket.fewnu.app/api/accolands", {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache", // Prevent caching
        },
      })
        .then((response) => response.json())
        .then((data) => data.data || []),
    ])
      .then(([articles, awardsResponse]) => {
        const newestArticle =
          articles.length > 0
            ? articles.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )[0]
            : null;
        setArticleWithId1(newestArticle);

        const newestAwards =
          awardsResponse.length > 0
            ? awardsResponse.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              )[0]
            : null;
        setAwards(newestAwards);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setArticleWithId1(null);
        setAwards(null);
      });
  }, []);

  const baseUrl = "http://api.ketket.fewnu.app/storage/";
  const imageUrl = articleWithId1 ? `${baseUrl}${articleWithId1.image}` : "";

  const handlePartnerClick = () => {
    navigate("/devenirPartenaire"); // Redirection to the partner page
  };

  return (
    <>
      <NavBar />
      <div className="pt-10 max-md:pt-0">
        <div className="hero-content-container w-[90%] mx-auto grid grid-cols-2 max-lg:grid-cols-1 gap-[30px] p-4 pt-12">
          <div className="hero-text-container col-span-1 w flex-col">
            <div className="hero-text bg-white">
              <div
                className="hero-title bg-hero-texture max-sm:mt-5 py-4 "
                style={{
                  backgroundSize: "400px",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <h1 className="font-arial font-semibold text-[60px] max-sm:text-[40px] text-main-blue scroll-py-32 max-sm:leading-[50px]">
                  Wellness Elevated <br /> by Partnership
                </h1>
              </div>
              <h2 className="text-3xl text -red-600 font-bold">
                Devenir un partenaire de Ket Ket
              </h2>
            </div>

            <div className="hero-subtitle py-2 pt-6 max-sm:py-0">
              <p className="text-justify my-2 font-medium">
                S'associer avec Ket Ket vous permet d'améliorer le bien-être de
                vos employés grâce à un accès facilité aux installations
                sportives amateurs.
              </p>

              <p className="text-justify my-4 font-medium">
                Rejoignez-nous pour transformer le bien-être au travail et créer
                un cadre propice à la productivité et à l'épanouissement des
                talents.
              </p>
            </div>

            <Link
              to={"devenir-partenaire"}
              className="flex max-sm:justify-center my-10 max-sm:mb-0 max-sm:mt-8 gap-4 "
            >
              <button
                // icon={<FontAwesomeIcon icon={faUser} />}

                className={
                  "bg-main-blue text-white text-sm  py-2 rounded-lg flex items-center px-4 gap-2 "
                }
              >
                <img src="./users.png" className="w-[20px]" /> Devenons
                partenaires
              </button>
            </Link>
          </div>

          {/* Right Section - Image */}
          <div className="flex h-auto justify-center">
            <img
              src={"../hero.jpg"}
              alt="Happy Employees"
              className="rounded-lg w-2/3 max-lg:w-[100%] bg-blue-900 shadow-md object-cover "
            />
          </div>
        </div>

        {/* About Section */}
        <About />
      </div>
    </>
  );
}

// About Component (Added as an extra section)
function About() {
  const [about, setAbout] = useState(null);

  useEffect(() => {
    // Fetch data from Laravel API
    fetch("https://www.api.ketket.fewnu.app/api/abouts", {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache", // Prevent caching
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the response contains valid data
        if (data.data && Array.isArray(data.data)) {
          const newestAbout = data.data[0] || null; // Take the first item if available
          setAbout(newestAbout);
        } else {
          console.warn("No valid data found in response");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const aboutImage1 = about
    ? `https://www.api.ketket.fewnu.app/storage/${about.image1}`
    : "";
  const aboutImage2 = about
    ? `https://www.api.ketket.fewnu.app/storage/${about.image2}`
    : "";
  const aboutImage3 = about
    ? `https://www.api.ketket.fewnu.app/storage/${about.image3}`
    : "";
  const aboutImage4 = about
    ? `https://www.api.ketket.fewnu.app/storage/${about.image4}`
    : "";
  const aboutImage5 = about
    ? `https://www.api.ketket.fewnu.app/storage/${about.image5}`
    : "";

  return (
    <div>
      <div className="relative w-[100%] flex place-content-evenly mt-16">
        <div className="mx-auto mb-10">
          <div className="text-center mx-[10%] max-sm:mx-[0%] font-arial font-semibold text-h3 leading-h3 text-main-black max-sm:text-h4 max-sm:leading-h4">
            {about?.title
              ? about.title
              : "Collaborez avec Ket Ket pour le Bien-Être des employés au Sénégal"}
          </div>
          <div className="mt-[-7%] ml-[90%]">
            <img className="w-20" src="../svg/squib2.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="mx-[7%] mt-3 max-sm:mt-8">
        <div className="about-container h-[100%] m-auto w-[88%] max-sm:w-[100%] relative max-ml:w-[92.5%]  mt-[5.1%] max-lg:w-[100%]">
          <img
            className="absolute left-[10.5%] w-[9.5%] -top-[3%] max-sm:-top-[3%]"
            src="../img/blue-x.png"
            alt=""
          />
          <img
            className="absolute left-[83.9%] w-[9.5%] top-[29.3%] -rotate-[15deg]"
            src="../img/blue-x.png"
            alt=""
          />
          <img
            className="absolute left-[86.5%] w-[7%] top-[65%] -rotate-[0deg]"
            src="../img/red-x.png"
            alt=""
          />
          <img
            className="absolute left-[20%] w-[7%] top-[65%] rotate-[13deg]"
            src="../img/red-x.png"
            alt=""
          />

          <div className="grid md:grid-cols-2 gap-5 place-items-center w-[100%] ">
            <div className="col-span-1">
              <div className="text-h4 mb-5 leading-h4 font-bold font-quicksand text-main-blue ">
                {about?.section1_title
                  ? about.section1_title
                  : "Ket Ket, C’est Quoi?"}
              </div>
              <div className="text-h6 text-sm max-md:text-justify font-bold font-quicksand leading-[22px]">
                {about?.section1_content
                  ? about.section1_content
                  : "Ket Ket est une entreprise sénégalaise dédiée à l'amélioration du bien-être des salariés en simplifiant l'accès aux structures sportives amateurs, en proposant des solutions d'adhésion aux entreprises pour soutenir et financer la pratique sportive de leurs employés."}
              </div>
            </div>

            <div className="">
              <img
                width={500}
                height={300}
                src={aboutImage1 ? aboutImage1 : "../img/macbook.png"}
                alt="Description of the "
              />
            </div>
          </div>
          {/**----------- */}
          <div className="about-blocks flex max-lg:flex-col-reverse">
            <div className="">
              <div
                className="macbook  max-lg:hidden relative mt-[5%] w-[88.95%] max-md:w-[100%]  rounded-[20px] z-10 "
                style={{
                  backgroundImage: aboutImage2
                    ? `url(${aboutImage2})`
                    : "url(../img/about2.png)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "360px",
                  height: "170px",
                }}
              ></div>

              <div
                className="relative bg-cover max-md:w-[100%] max-md:mt-[40px] max-lg:mt-10  bg-center macbook vertical-image w-[180px] h-[250px] -mt-[12%] ml-[4.9%] z-30 rounded-[20px] max-lg:w-[95%] max-lg:min-h-[200px] max-lg:mx-auto  "
                style={{
                  backgroundImage: aboutImage3
                    ? `url(${aboutImage3})`
                    : "url(../img/about3.png)",
                }}
              ></div>

              <div
                className="macbook max-lg:hidden vertical-image w-[210px] h-[120px] -mt-[39%] ml-[48%] z-6 rounded-[20px] 
               bg-cover bg-center relative right-[-40px] top-[-30px] max-[1700px]:-mt-[35%] 
               max-[1750px]:w-[53%] max-[1600px]:-mt-[38%] max-ml:-mt-[38%] max-xl:-mt-[43%] 
               max-lg:w-[95%] max-lg:min-h-[200px] max-lg:mx-auto max-lg:-mt-0 "
                style={{
                  backgroundImage: aboutImage3
                    ? `url(${aboutImage3})`
                    : "url(../img/pairgloves.jpg)",
                }}
              >
                <img
                  src="../img/red-circle.png"
                  className="absolute  w-[15%] -mt-[12.5%] ml-[13%]  transform -translate-x-1/2 -translate-y-1/2 z-0 max-lg:hidden"
                  alt=""
                />
              </div>
            </div>

            {/************* */}

            <div className="about-des1 w-[100%] mt-[1.55%] max-sm:mt-0 ml-[16.4%] max-sm:w-[100%] max-sm:mx-0 max-sm:ml-[0%] flex-col max-ml:w-[48.5%] max-ml:ml-auto max-lg:w-[95%] ">
              <div className="text-h4 leading-[39px]  font-bold font-quicksand text-main-blue mt-[16.55%] max-sm:mt-0 max-lg:ml-auto ">
                {about?.section2_title
                  ? about.section2_title
                  : "Accès au sport & promotion d'un mode de vie actif"}
              </div>

              <div className=" text-h6 text-sm font-quicksand leading-[28px] font-semibold w-[92%]  max-sm:w-[100%] max-sm:text-justify mt-[1.7%]">
                {about?.section2_content
                  ? about.section2_content
                  : "Ket Ket facilite l'accès des salariés à des installations sportives de qualité, encourage une pratique régulière du sport et soutient le financement des activités sportives par les entreprises pour promouvoir un mode de vie actif, réduire le stress et renforcer les liens sociaux."}
              </div>
            </div>
          </div>

          <div className="about-blocks mt-[0%] flex h-[45%] w-[100%] max-lg:flex-col max-lg:h-[100%] max-lg:mt-[5%] max-md:mt-[0px] max-md:mx-0">
            <div className="about-des1 w-[100%] ml-[4.75%] max-md:ml-0 h-[100%] flex-col max-ml:w-[48.5%] max-ml:ml-[0] max-lg:w-[95%] max-md:w-[100%] max-lg:h-[40%] max-lg:ml-auto ">
              <div className="text-h4 leading-[39px] w-[90%] font-bold font-quicksand text-main-blue mt-[20.55%] max-md:mt-[15%]">
                {about?.description
                  ? about.description
                  : "Bien-être global et développement personnel"}
              </div>

              <div className="text-h6 font-quicksand text-sm leading-[28px] font-semibold w-[90%] max-md:w-[100%] mt-[3.25%] max-md:text-justify max-md:mx-0">
                {about?.extra_info
                  ? about.extra_info
                  : "Chez Ket Ket, notre engagement est de promouvoir le bien-être des salariés par des programmes sportifs qui améliorent la santé physique, renforcent la confiance en soi et soutiennent le développement personnel pour une atmosphère de travail équilibrée et productive."}
              </div>
            </div>

            <div className="relative h-[45%] w-[48%] ml-[5%] mt-[1.5%] max-ml:w-[48.5%] max-ml:ml-auto max-ml:mt-[6%] max-xl:mt-[10%] flex flex-col justify-end max-lg:w-[100%] max-lg:m-auto max-lg:mt-[5%]">
              <img
                className="macbook relative w-[81.5%] max-md:w-[100%] h-[90%] rounded-[20px] max-lg:m-auto ml-[20%]"
                src={aboutImage5 ? aboutImage5 : "../img/about5.png"}
                alt="Description of the image"
              />
              {/* <div className="h-[5%] -mt-[20%]">
              <img
                className="ml-[72.5%] w-[35%]"
                src="../img/red-circle.png"
                alt=""
              />
            </div> */}
            </div>
          </div>
        </div>
      </div>

      <Logos label={"Ils nous font confiance"} />
      <EtrePartenaire />
      <DevenirPartenaireComponent />
      <Logos label={"Ils nous font confiance"} />
      <CardService />
      <RechercheSection />
      <div className="">
        <Footer />
      </div>
    </div>
  );
}
