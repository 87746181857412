import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
// import { MdMenu } from "react-icons/md";
import {
  faArrowRight,
  faBars,
  faMapMarked,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Bouton from "./Bouton";

export default function NavBar() {
  const location = useLocation();
  console.log(location);

  const [open, setOpen] = useState(false);
  return (
    <div className="bg-white px-0 mx-0 shadow-md fixed z-50 w-full">
      <div className=" mx-auto w-[90%] flex  flex-row justify-between py-3">

        <Link to={"/"} className="logo w-[100px] lg:w-[150px] flex items-center">
          <img className="" src="../logo1.png" alt="Logo de ketket" />
        </Link>
        <div className="hidden md:flex gap-5   items-center text-[#333333]">
          <NavLink
            className={` uppercase Navlinks text-[14px] lg:text-sm   text-[#333333]  font-bold ${
              location.pathname === "/" ? "AfterLink" : ""
            }`}
            to={"/"}
          >
            Accueil
          </NavLink>
          <NavLink
            className={`uppercase Navlinks font-bold text-sm   text-[#333333] ${
              location.pathname === "/partenaires" ||
              location.pathname === "/partenaires/devenirPartenaire"
                ? "AfterLink"
                : ""
            }`}
            to={"/partenaires"}
          >
            Partenaires
          </NavLink>
        </div>
        <div className="flex items-center gap-4 ">
          <NavLink
            to="/recherche"
            className=" items-center hidden md:flex py-1 px-4 text-sm font-medium gap-2 bg-main-blue border-2 border-[#1D428A] text-white rounded-full" // Augmenté légèrement la largeur
          >
            <img src="../img/mouse-pointer.png" className="w-[20px]" />
            <span className="text-md">Recherche</span>
          </NavLink>
          {/* <MdMenu /> */}
          {open ? (
            <FontAwesomeIcon
              onClick={() => setOpen(!open)}
              icon={faTimes}
              className="curseur-pointer md:hidden block text-xl"
            />
          ) : (
            <FontAwesomeIcon
              onClick={() => setOpen(!open)}
              icon={faBars}
              className="curseur-pointer md:hidden block text-xl"
            />
          )}

          <Bouton
            label="Prenez rendez-vous"
            className=" bg-main-red hidden md:block text-white  font-semibold rounded-full py-2 px-3 text-sm " // Augmenté la largeur et diminué l'espacement
          />

          <div
            className={`
        md:hidden bg-white fixed w-full  text-2xl h-full top-0 overflow-y-auto bottom-0 pt-2 shadow-sm opacity- pl-
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
          >
            <div className="flex justify-between items-center px-5 py-2 pt">
              <div className="logo w-[100px] flex items-center">
                <img className="" src="../logo1.png" alt="Logo de ketket" />
              </div>
              <FontAwesomeIcon
                onClick={() => setOpen(!open)}
                icon={faTimes}
                className="curseur-pointer md:hidden block text-3xl"
              />
            </div>

            <div className="flex flex-col px-9 items-center py-6 gap-5 justify-center">
              <NavLink
                className={` uppercase Navlinks text-[14px] lg:text-sm   text-[#333333]  font-normal ${
                  location.pathname === "/" ? "AfterLink" : ""
                }`}
                to={"/"}
              >
                Accueil
              </NavLink>
              <NavLink
                className={`uppercase Navlinks font-normal text-sm   text-[#333333] ${
                  location.pathname.includes("/partenaires") ? "AfterLink" : ""
                }`}
                to={"/partenaires"}
              >
                Partenaires
              </NavLink>

              <Bouton
                label="Prenez rendez-vous"
                className=" bg-main-red text-white text-center w-full flex items-center justify-center mx-auto  font-semibold rounded-full py-2 px-3 text-sm " // Augmenté la largeur et diminué l'espacement
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
