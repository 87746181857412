import React from "react";

export const SelectComponent = ({ name, children, options }) => {
  return (
    <>
      <label className="form-label mb-2 text-sm " htmlFor={name}>
        {children}
      </label>
      <select
        id={name}
        className="rounded-sm font-semibold border-0 text-[12px] ring-1 ring-[#EEF4FF] outline-0 py-2.5 px-2 bg-[#F6F9FF] text-[#000]  placeholder:text-[#000]  sm:text-sm md:text-[12px] sm:leading-6"
        name={name}
        //   class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
      >
        {options.map((option, index) => (
          <option className="font-semibold" key={index}>{option}</option>
        ))}
      </select>
    </>
  );
};
