import React from "react"; // Import React
import NavBar from "./components/navbar";
import Hero from "./components/hero";
import About from "./components/about";
import Advantage from "./components/advantage";
import Service from "./components/service";
import Partenaire from "./components/partenaire";
import Logos from "./components/partenaire_logos";
import Activities from "./components/activity";
import Search_Section from "./components/search_section";
import { Footer } from "./components/Footer";

export default function App() {
  return (
    <>
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </head>
      <body>
        <NavBar />
        <Hero />
        <div className="">
          <About />
        </div>
        <div className="">
          <Logos label={"Les clients qui nous font confiance"} />
        </div>
        <div className="">
          <Advantage />
        </div>
        {/* <div className="">
          <Service />
        </div> */}
        <div className="">
          <Activities />
        </div>
        {/* <div className="">
          <Partenaire />
        </div> */}
        <div className="">
          <Footer />
        </div>
        <div className="">{/* <Search_Section /> */}</div>
      </body>
    </>
  );
}
