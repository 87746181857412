const InputLabel = ({ type, name, children, placeholder }) => (
  <div className="flex flex-col w-full mb-2 md:mb-1">
    <label className="form-label mb-2 text-[12px] md:text-sm " htmlFor={name}>
      {children}
    </label>
    <input
      className="rounded-sm border-0 ring-1 ring-[#EEF4FF] outline-0 py-1.5 px-2 bg-[#F6F9FF] text-[#C8C8C8]  placeholder:text-[C8C8C8]  sm:text-sm md:text-[12px] sm:leading-6"
      placeholder={
        name === "nom"
          ? "eg: Ndiaye "
          : name === "prenom"
          ? "eg: Cheikh "
          : name === "email"
          ? "eg: CheikhNdiaye@email.com "
          : name === "telephone"
          ? "77XXXXXXX"
          : name === "entreprise"
          ? "eg: Ket Ket"
          : name === "position"
          ? "eg: Responsable des Ressources Humaines "
          : "placeholder"
      }
      type={type}
      id={name}
      name={name}
      // value={value}
      // onChange={onChange}
      required
    />
  </div>
);

export default InputLabel;
