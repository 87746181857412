import React from "react";

export const EtrePartenaire = () => {
  return (
    <div className="flex flex-col gap-[10%] mx-auto max-w-[90%] max-md:mb-[5%] ">
      {/*  */}
      <div className="mt-8 max-md:mt-0 service-container ">
        <h4 className="text-h4 leading-h4 text-start ml-[100px] max-md:ml-0 font-Quicksand md:pb-11 font-bold text-main-blue w-[100%] h-[30px] mb-[4%] max-lg:w-[80%] max-sm:text-h5 max-sm:leading-h5">
          Pourquoi être partenaire avec Ket Ket ?
        </h4>
        {/* <div className="flex justify-between items-center pt-8 pb-12">
        {LOGOS.map((logo, index) => (
          <div className=" w-[130px]">
            <img
              className="  object-cover "
              key={index}
              src={logo.src}
              alt={`Logo ${index + 1}`}
            />
          </div>
        ))}
      </div> */}

        <div className="service-main-container w-[100vw] max-md:w-[100%] flex justify-center max-lg:content-center ">
          <section className="hidescrollbar w-[100vw] max-md:w-[100%]">
            <div className="service-main-content mt-[3%] relative w-[1700px] max-lg:mx-auto max-2xl:gap-[41px] flex max-lg:flex-col gap-[41px] z-30 max-lg:w-[350px] max-md:mx-auto ml-[11%]">
              <div className="relative w-[265px] max-md:w-full max-md:mb-[100px] text-medium flex-shrink-0 bg-white z-50 rounded-[12px] shadow-service-shade flex flex-col">
                <div className="card-top bg-sample-class relative h-[50px] rounded-t-[12px] z-30">
                  <div className="py-[6px] bg-black"></div>
                  <div className="flex">
                    <div className="rounded-full h-[40px] w-[40px] relative z-40 bg-black text-white ml-[5%] my-[2%] flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        // className="feather feather-heart"
                        className="feather feather-heart simple-icon-class w-[32px] h-[32px]"
                      >
                        <path d="M20.84 4.61c-1.54-1.36-3.98-1.36-5.52 0L12 7.09l-3.32-2.48c-1.54-1.36-3.98-1.36-5.52 0-1.8 1.6-1.8 4.2 0 5.8L12 21l8.84-10.59c1.8-1.6 1.8-4.2 0-5.8z"></path>
                      </svg>
                    </div>
                    <div className=" w-[80%] h-[2px] bg-black text-white my-[10%] flex items-center justify-center"></div>
                  </div>
                </div>
                <div className="flex flex-col gap-[10px] relative z-50">
                  <h6 className="text-black text-[16px] ml-[5.15%] w-[78%] pt-[7.6%] font-bold">
                    Amélioration du bien-être en entreprise
                  </h6>
                  <p className="font-arial text-[11px] ml-[5.15%] w-[93%] leading-[25px] mb-2">
                    En devenant partenaire de Ket Ket, vous contribuez à
                    améliorer le bien-être de vos employés. Cette collaboration
                    permet d'offrir des solutions innovantes pour favoriser la
                    santé et le dynamisme de votre équipe, renforçant ainsi la
                    productivité et la satisfaction au travail.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-main-content relative w-[1700px] max-lg:mx-auto max-2xl:gap-[41px] flex max-lg:flex-col gap-[41px] z-30 max-lg:w-[350px] -mt-[17%] ml-[34%]">
              <div className="relative w-[265px] max-md:w-full max-md:mb-[115px] text-medium flex-shrink-0 bg-white z-50 rounded-[12px] shadow-service-shade flex flex-col">
                <div className="card-top bg-sample-class relative h-[50px] rounded-t-[12px] z-30">
                  <div className="py-[6px] bg-[#C8102E]"></div>
                  <div className="flex">
                    <div className="rounded-full h-[40px] w-[40px] relative z-40 bg-[#C8102E] text-white ml-[5%] my-[2%] flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        // className="feather feather-heart"
                        className="feather feather-heart simple-icon-class w-[32px] h-[32px]"
                      >
                        <path d="M20.84 4.61c-1.54-1.36-3.98-1.36-5.52 0L12 7.09l-3.32-2.48c-1.54-1.36-3.98-1.36-5.52 0-1.8 1.6-1.8 4.2 0 5.8L12 21l8.84-10.59c1.8-1.6 1.8-4.2 0-5.8z"></path>
                      </svg>
                    </div>
                    <div className=" w-[80%] h-[2px] bg-[#C8102E] text-white my-[10%] flex items-center justify-center"></div>
                  </div>
                </div>
                <div className="flex flex-col gap-[10px] relative z-50">
                  <h6 className="text-black text-[16px] ml-[5.15%] w-[78%] pt-[7.6%] font-bold">
                    Investissement dans la performance et la durabilité
                  </h6>
                  <p className="font-arial text-[11px] ml-[5.15%] w-[93%] leading-[25px] mb-2">
                    Collaborer avec Ket Ket est un investissement stratégique
                    pour la performance durable de votre entreprise. En
                    intégrant des programmes de sport et de bien-être, vous
                    stimulez l'engagement des employés, réduisez les coûts liés
                    à l'absentéisme et améliorez la satisfaction client,
                    renforçant ainsi votre position sur le marché.
                  </p>
                </div>
              </div>
            </div>
            <div className="service-main-content relative w-[1700px] max-lg:mx-auto max-2xl:gap-[41px] flex max-lg:flex-col gap-[41px] z-30 max-lg:w-[350px] -mt-[21%] ml-[57%]">
              <div className="relative w-[265px] max-md:w-full max-md:mb-[100px] text-medium flex-shrink-0 bg-white z-50 rounded-[12px] shadow-service-shade flex flex-col">
                <div className="card-top bg-sample-class relative h-[50px] rounded-t-[12px] z-30">
                  <div className="py-[6px] bg-[#1D428A]"></div>
                  <div className="flex">
                    <div className="rounded-full h-[40px] w-[40px] relative z-40 bg-[#1D428A] text-white ml-[5%] my-[2%] flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        // className="feather feather-heart"
                        className="feather feather-heart simple-icon-class w-[32px] h-[32px]"
                      >
                        <path d="M20.84 4.61c-1.54-1.36-3.98-1.36-5.52 0L12 7.09l-3.32-2.48c-1.54-1.36-3.98-1.36-5.52 0-1.8 1.6-1.8 4.2 0 5.8L12 21l8.84-10.59c1.8-1.6 1.8-4.2 0-5.8z"></path>
                      </svg>
                    </div>
                    <div className=" w-[80%] h-[2px] bg-[#1D428A] text-white my-[10%] flex items-center justify-center"></div>
                  </div>
                </div>
                <div className="flex flex-col gap-[10px] relative z-50">
                  <h6 className="text-black text-[16px] ml-[5.15%] w-[78%] pt-[7.6%] font-bold">
                    Investissement dans la performance et la durabilité
                  </h6>
                  <p className="font-arial text-[11px] ml-[5.15%] w-[93%] leading-[25px] mb-2">
                    Collaborer avec Ket Ket est un investissement stratégique
                    pour la performance durable de votre entreprise. En
                    intégrant des programmes de sport et de bien-être, vous
                    stimulez l'engagement des employés, réduisez les coûts liés
                    à l'absentéisme et améliorez la satisfaction client,
                    renforçant ainsi votre position sur le marché.
                  </p>
                </div>
              </div>
            </div>

            <div className="red-circleRight mt-[-48%] h-[0.35%] z-10 max-md:mt-[-60%]">
              <img
                className="w-[150px] h-[px] mt-[5%] ml-[6%]  max-md:ml-[-10%]"
                src="../img/red-circle.png"
                alt=""
              />
            </div>
            <div className="red-circleRight h-[0.35%] z-10 max-md:hidden">
              <img
                className="w-[150px] mt-[26%] h-[px] ml-[71%] "
                src="../img/red-circle.png"
                alt=""
              />
            </div>
          </section>
        </div>
      </div>
      {/*  */}
      <div className="squibbly-container w-[180px] h-[100%] max-lg:hidden">
        <div className="squib ml-[1%] mt-[-450%] ">
          <img className="w-[38%]" src="../img/blue-vector.png" alt="" />
        </div>
      </div>
    </div>
  );
};
