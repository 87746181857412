import React from "react";
import InputLabel from "./InputLabel";
import { TextareaLabel } from "./TextareaLabel";
import { SelectComponent } from "./SelectComponent";

export const FormSport = () => {
  const options = [
    "select",
    "1 a 10",
    "de 11 á 50",
    "de 51 á 100",
    "de 101 á 201",
    "de 201 á 500",
    "de 501 á 1000",
    "de 1001 á 1500",
    "plus de 100",
  ];
  return (
    <>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <InputLabel
          type="text"
          name="prenom"
          //   value={state.prenom}
          //   onChange={handleChange}
          children="Prenom"
        />
        <InputLabel
          type="text"
          name="entreprise"
          //   value={state.entreprise}
          // onChange={handleChange}
          children="Noms de votre entreprise"
        />
      </div>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <InputLabel
          type="text"
          name="nom"
          //   value={state.nom}
          //   onChange={handleChange}
          children="Nom"
        />
        <InputLabel
          type="email"
          name="email"
          children="E-mail du RH ou du Responsable "
          //   value={state.position}
          //   onChange={handleChange}
        />
      </div>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <InputLabel
          type="email"
          name="email"
          // value={state.email}
          // onChange={handleChange}
          children="E-mail"
        />
        <InputLabel
          type="tel"
          name="telephone"
          children="Numéro de téléphone RH/Responsable"
          //  value={state.telephone}
          //  onChange={handleChange}
        />
      </div>
      <div className="mb-3 sm:flex gap-3 mx-auto">
        <div className="mb-2 sm:flex flex-col w-full md:w-1/2 gap-3 ">
          <InputLabel
            type="tel"
            name="telephone"
            children="Numéro de téléphone"
            //   value={state.telephone}
            //   onChange={handleChange}
          />

          <div className="flex flex-col w-full">
            <SelectComponent
              name="date"
              children="Date disponible"
              options={options}
            />
          </div>
        </div>
        <TextareaLabel
          label="Description de votre entreprise"
          placeholder="Message"
        />
      </div>
    </>
  );
};
