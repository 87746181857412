import React, { useEffect, useRef, useState } from "react";

const Logos = ({ label }) => {
  const [logos, setLogos] = useState([]);
  const [useStaticImage, setUseStaticImage] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.api.ketket.fewnu.app/api/operations",
          {
            method: "GET",
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );
        const data = await response.json();

        let fetchedLogos = [];
        if (data.data && Array.isArray(data.data)) {
          fetchedLogos = data.data;
          setUseStaticImage(false); // Use dynamic data
        } else {
          console.warn("No valid data found in response");
        }

        // Set logos to be fetched data or static data as needed
        setLogos(fetchedLogos.length ? fetchedLogos : getFallbackData());
      } catch (error) {
        console.error("Error fetching data:", error);
        // Use static images if there's an error
        setLogos(getFallbackData());
        setUseStaticImage(true); // Use static images
      }
    };

    fetchData();
  }, []);

  const getFallbackData = () => [
    "/img/parter/senelec.png",
    "/img/parter/baobab.png",
    "/img/parter/elton.png",
    "/img/parter/wave.png",
    "/img/parter/prem.png",
    "/img/parter/senelec.png",
    "/img/parter/baobab.png",
    "/img/parter/elton.png",
    "/img/parter/wave.png",
  ];

  const LOGOS = [
    { src: "/img/parter/senelec.png" },
    { src: "/img/parter/baobab.png" },
    { src: "/img/parter/elton.png" },
    { src: "/img/parter/wave.png" },
    { src: "/img/parter/prem.png" },
    { src: "/img/parter/senelec.png" },
    { src: "/img/parter/baobab.png" },
    { src: "/img/parter/elton.png" },
    { src: "/img/parter/wave.png" },
  ];

  const fallbackImages = [
    "/img/service/service1.png",
    "/img/service/service2.png",
    "/img/service/service4.png",
    "/img/service/service3.png",
  ];

  const bgClasses = [
    "bg-main-blue",
    "bg-service-2",
    "bg-service-3",
    "bg-main-red",
  ];

  const bgClassesVertical = [
    "bg-main-blue",
    "bg-service-2",
    "bg-service-3",
    "bg-main-red",
  ];

  const getBgClass = (index) => {
    return bgClasses[index % bgClasses.length];
  };

  const getBgClassVertical = (index) => {
    return bgClassesVertical[index % bgClassesVertical.length];
  };

  //
  const [services, setServices] = useState([]);
  useEffect(() => {
    // Define a timeout promise
    const timeoutPromise = new Promise(
      (_, reject) =>
        setTimeout(() => reject(new Error("Request timed out")), 5000) // 5 seconds timeout
    );

    // Fetch data from API
    const fetchPromise = fetch(
      "https://www.api.ketket.fewnu.app/api/services",
      {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
        },
      }
    ).then((response) => response.json());

    // Race between fetch and timeout
    Promise.race([fetchPromise, timeoutPromise])
      .then((data) => {
        // if (data.data && Array.isArray(data.data)) {
        //   const latestServices = data.data.slice(-4);
        //   setServices(latestServices);
        //   setUseStaticImage(false); // Use server images if available
        // } else {
        console.warn("No valid data found in response", data);
        setServices(getFallbackData());
        setUseStaticImage(true); // Use static images
        // }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setServices(getFallbackData());
        setUseStaticImage(true); // Use static images if there's an error
      });
  }, []);
  //

  return (
    <div className="flex flex-col gap-[10%] mt-[70px] mb-[50px] max-md:mt-[15%] mx-auto w-[90%] max-lg:w-[80%] ">
      <h4 className="text-h4 leading-h4 text-center font-arial font-bold text-black w-[100%] h-[30px] mb-[2.5%] max-md:mb-[5%] max-lg:w-[80%] max-md:w-[100%] max-sm:text-h5 max-sm:leading-h5 ">
        {logos?.title ? logos.title : label}
      </h4>
      <div className="flex justify-between items-center pt-8 pb-12 lg:pb-0">
        {LOGOS.map((logo, index) => (
          <div className=" w-[130px]">
            <img
              className="  object-cover "
              key={index}
              src={logo.src}
              alt={`Logo ${index + 1}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Logos;
