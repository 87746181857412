import InputLabel from "./InputLabel";
import { TextareaLabel } from "./TextareaLabel";
import { ButtonSubmit } from "./ButtonSubmit";
// { handleChange, handleSubmit, state, modifierId }
const Form = ({ FormContainer }) => {
  return (
    <form className=" w-full font-semibold  p-5 mb-3">
      {FormContainer}
      <ButtonSubmit />
    </form>
  );
};

export default Form;
