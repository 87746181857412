import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, LayersControl, Popup } from 'react-leaflet';
import { useQuery } from '@tanstack/react-query';
import 'leaflet/dist/leaflet.css';

const fetchLocations = async () => {
    const response = await fetch('https://www.api.ketket.fewnu.app/api/partenaire-sports', {
        method: 'GET',
        headers: {
            'Cache-Control': 'no-cache',
        },
    });
    const data = await response.json();
    if (data.data && Array.isArray(data.data)) {
        return data.data;
    } else {
        console.warn('No valid data found in response, using fallback data.');
        return fallbackLocations; // Utilisez les données de fallback si la réponse est invalide
    }
};

const fallbackLocations = [
    { nom: "RMS Static", latitude: 14.6900, longitude: -17.4500 },
    { nom: "Life Static", latitude: 14.6950, longitude: -17.4600 },
    { nom: "Fallback Sport 3", latitude: 14.7000, longitude: -17.4700 },
];

export default function Map({ latitude = 14.71128, longitude = -17.4567, entrepriseName }) {
    const defaultPosition = [Number(latitude), Number(longitude)];
    const [positions, setPositions] = useState(defaultPosition);
    
    // Utiliser useQuery pour récupérer les emplacements
    // const { data: locations = [], isLoading, isError } = useQuery(['locations'], fetchLocations);
    const { data: locations = [], isLoading, isError } = useQuery({
    queryKey: ['locations'],  // L'objet queryKey
    queryFn: fetchLocations,   // La fonction de récupération des données
});

    const getZoom = (locations) => {
        if (locations.length > 30) return 20;
        if (locations.length > 5) return 25;
        return 28;
    };

    const zoomLevel = getZoom(locations);

    useEffect(() => {
        setPositions([latitude, longitude]);
    }, [latitude, longitude]);

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error fetching locations.</div>;

    return (
        <MapContainer center={positions} zoom={zoomLevel} style={{ height: '100vh', width: '100%' }}>
            <LayersControl>
                <LayersControl.BaseLayer checked name="Terrain - World Imagery">
                    <TileLayer
                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        attribution='<a href="Tiles &copy; Esri">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer checked name="Jawg-Lagoon">
                    <TileLayer
                        url='https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}{r}.png?access-token=2U5baWI92SCFF5D9Gp53vRanR2r9g5TQ6X5qhEY4Z0tIQUijlbOEbW2eZmOLGfx9'
                        attribution='<a href="https://jawg.io">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                </LayersControl.BaseLayer>

                {locations.map(({ nom, latitude, longitude }, index) => (
                    <Marker
                        key={index}
                        position={[latitude ?? 14.8928, longitude ?? -17.4467]}
                    >
                        <Popup>
                            <div>
                                <h2 className='font-quicksand text-main-red font-semibold'>{nom}</h2>
                                <p>Latitude: {latitude}</p>
                                <p>Longitude: {longitude}</p>
                            </div>
                        </Popup>
                    </Marker>
                ))}
                <Marker position={positions}>
                    <Popup>
                        <h2 className='text-h4 font-quicksand text-main-red font-semibold'>{entrepriseName}</h2>
                        <p>Latitude: {latitude}</p>
                        <p>Longitude: {longitude}</p>
                    </Popup>
                </Marker>
            </LayersControl>
        </MapContainer>
    );
}
