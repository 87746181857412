import React, { useEffect, useState } from "react";

export default function Advantage() {
  const [advantage, setAdvantage] = useState(null);
  const [stat, setStat] = useState(null);

  useEffect(() => {
    // Fetch data from Laravel API
    fetch("https://www.api.ketket.fewnu.app/api/advantages", {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache", // Prevent caching
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the response contains valid data
        if (data.data && Array.isArray(data.data)) {
          const newestAdvantage = data.data[0] || null; // Take the first item if available
          setAdvantage(newestAdvantage);
        } else {
          console.warn("No valid data found in response");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch data from Laravel API
    fetch("https://www.api.ketket.fewnu.app/api/statistics", {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache", // Prevent caching
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the response contains valid data
        if (data.data && Array.isArray(data.data)) {
          const newestStat = data.data[0] || null; // Take the first item if available
          setStat(newestStat);
        } else {
          console.warn("No valid data found in response");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  console.log(advantage);

  const hideScrollBarStyle = {
    msOverflowStyle: "none", // for Internet Explorer and Edge
    scrollbarWidth: "none", // for Firefox
  };

  const hideScrollBarWebkit = {
    // for Webkit browsers (Chrome, Safari)
    overflow: "hidden",
  };

  return (
    <>
      <div className="advantage-title relative z-2 w-[100%] flex place-content-evenly max-lg:mt-[30%] max-md:mt-[0%] ">
        <div
          className="relative w-[100%] max-md:mx-auto bg-dots-advantage bg-center bg-no-repeat flex flex-col pb-4"
          style={{ backgroundSize: "1300px" }}
        >
          {/* <div className="text-center mb-[1.25%] w-[90%] max-md:mx-auto max-ml:mt-[2.5%] max-ml:mb-[2.5%] max-lg:mt-[7.5%] max-sm:pt-[0.4%]">
            <h4 className="text-h4 leading-h4 text-center font-arial md:pb-11 font-bold text-main-black w-[100%] h-[30px] max-lg:w-[80%] max-md:w-[90%] max-md:text-center mx-auto max-sm:text-h5 max-sm:leading-h5 max-sm:mb-[5%] ">
              {advantage?.titre
                ? advantage.titre
                : "Nos atouts, notre avantage"}
            </h4>
          </div> */}

          <h4 className="text-h4 leading-h4 text-center font-arial font-bold text-black w-[100%] h-[30px] mb-[1.5%] max-md:mb-[5%] max-lg:w-[80%] max-md:w-[100%] max-sm:text-h5 max-sm:leading-h5 ">
            {advantage?.titre ? advantage.titre : "Nos atouts, notre avantage"}
          </h4>

          <div className="w-[84.5%] mx-auto mt-[0.8%] flex flex-col items-center">
            <div className="wrapper w-[99.38%] flex  overflow-y-hidden flex-grow-0 mr-auto  ">
              <section className="h-[305px] w-[1620px] mx-auto relative overflow-y-hidden scrollbar-hide">
                <div className="advantage-container flex flex-grow-0 h-[100%] overflow-hidden w-[2200px] gap-[20px] relative max-2xl:h-[100%]">
                  <div className="relative adv-card bg-main-blue h-[100%] w-[526px] rounded-[15px] flex-col max-sm:w-[320px]">
                    <div className=" text-[20px] leading-[26px] h-[55px] flex align-middle text-white font-bold font-quicksand w-[70%] mt-[6.35%] text-left ml-[4%] max-sm:text-h6 max-sm:leading-h6 max-sm:w-[83%]">
                      {advantage?.sous_titre
                        ? advantage.sous_titre
                        : "Installations ultramodernes"}
                    </div>
                    <div className="white-line h-[0.65%] w-[90.15%] bg-white m-auto mt-[2.35%] "></div>
                    <div className="paragraph-card text-sm text-white text-left font-arial font-[700] leading-[32px] w-[91%] mt-[3.75%] ml-[4%] ">
                      {advantage?.description
                        ? advantage.description
                        : "Lorem ipsum dolor sit amet consectetur. Pellentesque sit lorem ac nec nulla habitasse elit. Sit consequat cursus integer nam risus amet urna. "}
                    </div>
                    <div className="text-[320px] font-r-mono font-bold  text-left text-white text-opacity-10 -mt-[50.25%] ml-[3%] mix-blend-overlay max-sm:-mt-[90%]">
                      1
                    </div>
                  </div>

                  <div className="relative adv-card bg-main-blue h-[100%] w-[524px] rounded-[15px] max-sm:w-[300px]">
                    <div className=" text-[20px] leading-[26px] h-[55px] text-white font-bold font-quicksand w-[82%] mt-[6.35%] text-left ml-[4%] max-sm:text-h6 max-sm:leading-h6 max-sm:w-[90%]">
                      {advantage?.sous_titre1
                        ? advantage.sous_titre1
                        : "Programme d'Entraînement Personnalisé"}
                    </div>

                    <div className="white-line h-[0.65%] w-[90.15%] bg-white m-auto mt-[2.35%] "></div>

                    <div className="paragraph-card text-sm text-white text-left font-arial font-[700] leading-[32px] w-[92%] mt-[3.75%] ml-[4%]">
                      {advantage?.description1
                        ? advantage.description1
                        : "Lorem ipsum dolor sit amet consectetur. Pellentesque sit lorem ac nec nulla habitasse elit. Sit consequat cursus integer nam risus amet urna. "}
                    </div>

                    <div className="text-[320px] font-r-mono font-bold  text-left text-white text-opacity-10 -mt-[50.25%] ml-[3%] mix-blend-overlay max-sm:-mt-[100%]">
                      2
                    </div>
                  </div>

                  <div className="relative adv-card bg-main-blue h-[100%] w-[524px] rounded-[15px] max-sm:w-[300px]">
                    <div className="text-[20px] leading-[26px] h-[55px] text-white font-bold font-quicksand w-[69%] mt-[6.35%] text-left ml-[4%] max-sm:text-h6 max-sm:leading-h6 max-sm:w-[78%]  ">
                      {advantage?.sous_titre2
                        ? advantage.sous_titre2
                        : "Communauté de Soutien"}
                    </div>

                    <div className="white-line h-[0.65%] w-[90.15%] bg-white m-auto mt-[2.35%] "></div>

                    <div className="paragraph-card text-sm text-white text-left font-arial font-[700] leading-[32px] w-[92%] mt-[3.75%] ml-[4%]">
                      {advantage?.description2
                        ? advantage.description2
                        : "Lorem ipsum dolor sit amet consectetur. Pellentesque sit lorem ac nec nulla habitasse elit. Sit consequat cursus integer nam risus amet urna. "}
                    </div>

                    <div className="text-[320px] font-r-mono font-bold  text-left text-white text-opacity-10 -mt-[50.25%] ml-[3%] mix-blend-overlay max-sm:-mt-[100%]">
                      3
                    </div>
                  </div>

                  <div className="relative adv-card bg-main-blue h-[100%] w-[524px] rounded-[15px] max-sm:w-[300px]">
                    <div className=" text-[31.25px] leading-[39px] text-white font-bold font-quicksand w-[63%] mt-[6.35%] text-left ml-[4%] max-sm:text-h6 max-sm:leading-h6 max-sm:w-[71%] ">
                      {advantage?.sous_titre3
                        ? advantage.sous_titre3
                        : "Installations de Pointe"}
                    </div>

                    <div className="white-line h-[0.65%] w-[90.15%] bg-white m-auto mt-[2.35%] "></div>

                    <div className="paragraph-card text-p text-white text-left font-r-mono font-normal leading-[32px] w-[92%] mt-[3.75%] ml-[4%]">
                      {advantage?.description3
                        ? advantage.description3
                        : "Lorem ipsum dolor sit amet consectetur. Pellentesque sit lorem ac nec nulla habitasse elit. Sit consequat cursus integer nam risus amet urna. "}
                    </div>

                    <div className="text-[320px] font-r-mono font-bold  text-left text-white text-opacity-10 -mt-[50.25%] ml-[3%] mix-blend-overlay max-sm:-mt-[100%]">
                      4
                    </div>
                  </div>

                  <div className="relative adv-card bg-main-blue h-[100%] w-[524px] rounded-[15px] max-sm:w-[300px]">
                    <div className=" text-[31.25px] leading-[39px] text-white font-bold font-quicksand w-[69%] mt-[6.35%] text-left ml-[4%] max-sm:text-h6 max-sm:leading-h6 max-sm:w-[90%] ">
                      {advantage?.sous_titre4
                        ? advantage.sous_titre4
                        : "Lorem ipsum dolor sit amet consectetur."}
                    </div>

                    <div className="white-line h-[0.65%] w-[90.15%] bg-white m-auto mt-[2.35%] "></div>

                    <div className="paragraph-card text-p text-white text-left font-r-mono font-normal leading-[32px] w-[92%] mt-[3.75%] ml-[4%]">
                      {advantage?.description4
                        ? advantage.description4
                        : "Lorem ipsum dolor sit amet consectetur. Pellentesque sit lorem ac nec nulla habitasse elit. Sit consequat cursus integer nam risus amet urna. "}
                    </div>

                    <div className="text-[320px] font-r-mono font-bold  text-left text-white text-opacity-10 -mt-[50.25%] ml-[3%] mix-blend-overlay max-sm:-mt-[100%]">
                      5
                    </div>
                  </div>

                  <div className="relative adv-card bg-main-blue h-[100%] w-[524px] rounded-[15px] max-sm:w-[300px]">
                    <div className=" text-[31.25px] leading-[39px] text-white font-bold font-quicksand w-[69%] mt-[6.35%] text-left ml-[4%] max-sm:text-h6 max-sm:leading-h6 max-sm:w-[90%] ">
                      {advantage?.sous_titre5
                        ? advantage.sous_titre5
                        : "Lorem ipsum dolor sit amet consectetur."}
                    </div>

                    <div className="white-line h-[0.65%] w-[90.15%] bg-white m-auto mt-[2.35%] "></div>

                    <div className="paragraph-card text-p text-white text-left font-r-mono font-normal leading-[32px] w-[92%] mt-[3.75%] ml-[4%]">
                      {advantage?.description5
                        ? advantage.description5
                        : "Lorem ipsum dolor sit amet consectetur. Pellentesque sit lorem ac nec nulla habitasse elit. Sit consequat cursus integer nam risus amet urna. "}
                    </div>

                    <div className="text-[320px] font-r-mono font-bold  text-left text-white text-opacity-10 -mt-[50.25%] ml-[3%] mix-blend-overlay max-sm:-mt-[100%]">
                      6
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* <div className="bg-white mr-[1%] flex items-end gap-[20px] mt-0 max-ml:mt-[2%] max-xl:mt-[3%] max-lg:hidden max-sm:hidden ">
              <div className="h-[8px] w-[50px] rounded-[50px] bg-[#D9D9D9] mt-auto mb-auto"></div>
              <div className="h-[12px] w-[80px] rounded-[50px] bg-main-red mt-auto mb-auto"></div>
              <div className="h-[8px] w-[50px] rounded-[50px] bg-[#D9D9D9] mt-auto mb-auto"></div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="advantage-blocks flex w-[100%] max-sm:mb-[50%] max-lg:mb-[20%] my-[100px] max-md:mt-[0%]">
        <div className="mx-auto flex flex-grow-0 place-content-evenly max-ml:w-[90%] max-lg:h-[35vh] max-lg:flex-col max-lg:gap-[30px] max-[400px]:mt-[5%] max-[420px]:mt-[10%] max-md:mt-[0%]">
          <div className="">
            <div className="text-[80px] font-[700] text-center font-arial text-black">
              {" "}
              {stat?.clients_satisfaits ? stat.clients_satisfaits : "750"}+
            </div>
            <div className="text-[20px] text-center -mt-[2%] leading-h4 font-arial font-bold">
              {stat?.additional_column1
                ? stat.additional_column1
                : "Client satisfaits"}
            </div>
          </div>
          <div className="">
            <div className="text-[80px] font-[700] text-center font-arial text-black">
              {" "}
              {stat?.avis_rares ? stat.avis_rares : "26"}+
            </div>
            <div className="text-[20px] text-center -mt-[2%]  leading-h4 font-arial font-bold">
              {stat?.additional_column2 ? stat.additional_column2 : "Activités"}
            </div>
          </div>
          <div className="  ">
            <div className="text-[80px] font-[700] text-center font-arial text-black">
              {" "}
              {stat?.sports_offerts ? stat.sports_offerts : "8"}+
            </div>
            <div className="text-[20px] text-center -mt-[2%]  leading-h4 font-arial font-bold">
              {stat?.additional_column3
                ? stat.additional_column3
                : "Enterprises clients"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
