import React from 'react'

export const TextareaLabel = ({label, placeholder}) => {
  return (
    <div className=" w-full md:w-1/2 mb-2 ">
          <label
            htmlFor="textarea"
            className="block text-sm font-semibold mb-2"
          >
            {label}
          </label>
          <textarea
            id="textarea"
            rows={5}
            placeholder={placeholder}
            className="bg-[#F6F9FF] text-[#C8C8C8]  placeholder:text-[C8C8C8] ring-1 w-full ring-[#EEF4FF] outline-0 p-2 sm:text-sm md:text-[12px]"
          ></textarea>
        </div>
  )
}
