import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const ButtonSubmit = () => {
  return (
    <div className="flex justify-end items-end">
      <button
        type="submit"
        className={"bg-main-blue rounded-lg p-4 py-2 text-white text-[13px] "}
      >
        Réserve maintenant{" "}
        <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
      </button>
    </div>
  );
};
