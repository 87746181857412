import React from 'react'
import InputLabel from './InputLabel'
import { TextareaLabel } from './TextareaLabel'
import { SelectComponent } from './SelectComponent'

export const FormCollaborateur = () => {
    const metiers = [
        "select",
        "Propriétaire d'une salle de sport",
        "Gérant d’une salle de sport",
        "Coach/Responsable d’une salle de sport",
        "Enseignant de sports de relaxation et de bien-être",
        "Enseignant de sports de combat",
        "Enseignant de sports collectifs",
        "Autres",
      ];
  return (
    <>
    <div className="mb-3 sm:flex  gap-3 mx-auto">
      <InputLabel
        type="text"
        name="prenom"
        //   value={state.prenom}
        //   onChange={handleChange}
        children="Prenom"
      />
      <InputLabel
        type="text"
        name="entreprise"
        //   value={state.entreprise}
        // onChange={handleChange}
        children="Noms de votre entreprise"
      />
    </div>
    <div className="mb-3 sm:flex gap-3 mx-auto">
      <InputLabel
        type="text"
        name="nom"
        //   value={state.nom}
        //   onChange={handleChange}
        children="Nom"
      />
      <InputLabel
        type="email"
        name="position"
        children="E-mail du RH ou du Responsable "
        //   value={state.position}
        //   onChange={handleChange}
      />
    </div>
    <div className="mb-3 sm:flex gap-3 mx-auto">
      <InputLabel
        type="email"
        name="email"
        // value={state.email}
        // onChange={handleChange}
        children="E-mail"
      />
      
      <div className="flex flex-col w-full md:w-1/2">
          <SelectComponent
            name="employés"
            children="Nombre d'employés"
            options={metiers}
          />
        </div>
    </div>
    <div className="mb-3 sm:flex gap-3 mx-auto">
      <div className="mb-2 sm:flex flex-col w-full md:w-1/2 gap-3 ">
        <InputLabel
          type="email"
          name="email"
          //   value={state.email}
          //   onChange={handleChange}
          children="Email"
        />
        <InputLabel
          type="tel"
          name="telephone"
          children="Telephone"
          //   value={state.telephone}
          //   onChange={handleChange}
        />
      </div>
      <TextareaLabel
        label="Description de votre entreprise"
        placeholder="Message"
      />
    </div>
  </>
  )
}
